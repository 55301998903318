export var AccrualReasonType;
(function (AccrualReasonType) {
    AccrualReasonType["Birthday"] = "Birthday";
    AccrualReasonType["ProbationPeriodEnd"] = "ProbationPeriodEnd";
    AccrualReasonType["WorkExperienceInTheCompany"] = "WorkExperienceInTheCompany";
    AccrualReasonType["Vkontakte"] = "Vkontakte";
    // Instagram = 'Instagram',
    // Facebook = 'Facebook',
})(AccrualReasonType || (AccrualReasonType = {}));
export var AccrualConditionType;
(function (AccrualConditionType) {
    AccrualConditionType["Subscription"] = "Subscription";
    AccrualConditionType["HashtagPost"] = "HashtagPost";
    AccrualConditionType["Repost"] = "Repost";
    AccrualConditionType["SpecificPostRepost"] = "SpecificPostRepost";
    AccrualConditionType["Mention"] = "Mention";
    AccrualConditionType["Like"] = "Like";
})(AccrualConditionType || (AccrualConditionType = {}));
export var ActivityTypesEnum;
(function (ActivityTypesEnum) {
    ActivityTypesEnum["Manual"] = "Manual";
    ActivityTypesEnum["Automated"] = "Automated";
    ActivityTypesEnum["Moodle"] = "Moodle";
})(ActivityTypesEnum || (ActivityTypesEnum = {}));
export var SupervisorRequestStatusType;
(function (SupervisorRequestStatusType) {
    SupervisorRequestStatusType["Waiting"] = "Waiting";
    SupervisorRequestStatusType["Approved"] = "Approved";
    SupervisorRequestStatusType["Declined"] = "Declined";
    SupervisorRequestStatusType["Canceled"] = "Canceled";
})(SupervisorRequestStatusType || (SupervisorRequestStatusType = {}));
export var ApprovalChainSteps;
(function (ApprovalChainSteps) {
    ApprovalChainSteps["Manager"] = "Manager";
    ApprovalChainSteps["DirectSupervisor"] = "DirectSupervisor";
})(ApprovalChainSteps || (ApprovalChainSteps = {}));
export var ApprovalChainType;
(function (ApprovalChainType) {
    ApprovalChainType["Sequential"] = "Sequential";
    ApprovalChainType["ParallelAny"] = "ParallelAny";
    ApprovalChainType["ParallelAll"] = "ParallelAll";
})(ApprovalChainType || (ApprovalChainType = {}));
export var StyleTypeDto;
(function (StyleTypeDto) {
    StyleTypeDto["boxShadow"] = "boxShadow";
    StyleTypeDto["color"] = "color";
})(StyleTypeDto || (StyleTypeDto = {}));
export var EmployeeImportFieldType;
(function (EmployeeImportFieldType) {
    EmployeeImportFieldType["ImportId"] = "ImportId";
    EmployeeImportFieldType["FirstName"] = "FirstName";
    EmployeeImportFieldType["LastName"] = "LastName";
    EmployeeImportFieldType["PersonnelNumber"] = "PersonnelNumber";
    EmployeeImportFieldType["MiddleName"] = "MiddleName";
    EmployeeImportFieldType["Email"] = "Email";
    EmployeeImportFieldType["Phone"] = "Phone";
    EmployeeImportFieldType["TealBalance"] = "TealBalance";
    EmployeeImportFieldType["BirthDate"] = "BirthDate";
    EmployeeImportFieldType["HireDate"] = "HireDate";
    EmployeeImportFieldType["FireDate"] = "FireDate";
    EmployeeImportFieldType["ProbationPeriodInMonths"] = "ProbationPeriodInMonths";
    EmployeeImportFieldType["TelegramUsername"] = "TelegramUsername";
    EmployeeImportFieldType["Position"] = "Position";
    EmployeeImportFieldType["EmployeePhoto"] = "EmployeePhoto";
    EmployeeImportFieldType["InstagramUsername"] = "InstagramUsername";
    EmployeeImportFieldType["VkontakteUsername"] = "VkontakteUsername";
    EmployeeImportFieldType["FacebookUsername"] = "FacebookUsername";
    EmployeeImportFieldType["SupervisorImportId"] = "SupervisorImportId";
    EmployeeImportFieldType["PeopleManagerImportId"] = "PeopleManagerImportId";
    EmployeeImportFieldType["PeopleManagerDelegateImportId"] = "PeopleManagerDelegateImportId";
})(EmployeeImportFieldType || (EmployeeImportFieldType = {}));
export var BuildStatusType;
(function (BuildStatusType) {
    BuildStatusType["Scheduled"] = "Scheduled";
    BuildStatusType["InProgress"] = "InProgress";
    BuildStatusType["Completed"] = "Completed";
    BuildStatusType["Error"] = "Error";
})(BuildStatusType || (BuildStatusType = {}));
export var EmployeesFilterFeatureKeyType;
(function (EmployeesFilterFeatureKeyType) {
    EmployeesFilterFeatureKeyType["Tags"] = "Tags";
    EmployeesFilterFeatureKeyType["Status"] = "Status";
    EmployeesFilterFeatureKeyType["BirthDate"] = "BirthDate";
    EmployeesFilterFeatureKeyType["HireDate"] = "HireDate";
    EmployeesFilterFeatureKeyType["Position"] = "Position";
    EmployeesFilterFeatureKeyType["FireDate"] = "FireDate";
    EmployeesFilterFeatureKeyType["ProbationEndDate"] = "ProbationEndDate";
    EmployeesFilterFeatureKeyType["Supervisors"] = "Supervisors";
    EmployeesFilterFeatureKeyType["PeopleManagers"] = "PeopleManagers";
    EmployeesFilterFeatureKeyType["PeopleManagerDelegates"] = "PeopleManagerDelegates";
})(EmployeesFilterFeatureKeyType || (EmployeesFilterFeatureKeyType = {}));
export var CompanyEnabledFeatureKeyType;
(function (CompanyEnabledFeatureKeyType) {
    CompanyEnabledFeatureKeyType["Vkontakte"] = "vkontakte";
    CompanyEnabledFeatureKeyType["Instagram"] = "instagram";
    CompanyEnabledFeatureKeyType["Telegram"] = "telegram";
    CompanyEnabledFeatureKeyType["Facebook"] = "facebook";
    CompanyEnabledFeatureKeyType["Slack"] = "slack";
    CompanyEnabledFeatureKeyType["X"] = "x";
    CompanyEnabledFeatureKeyType["LinkedIn"] = "linkedIn";
    CompanyEnabledFeatureKeyType["Achievements"] = "achievements";
    CompanyEnabledFeatureKeyType["MyDevelopment"] = "myDevelopment";
    CompanyEnabledFeatureKeyType["AddressBookHideEmptyFieldsFilter"] = "address-book-hide-empty-fields-filter";
    CompanyEnabledFeatureKeyType["AddressBookDisplayEmail"] = "address-book-display-email";
    CompanyEnabledFeatureKeyType["AddressBookDisplaySocialNetworks"] = "address-book-display-social-networks";
    CompanyEnabledFeatureKeyType["AddressBookDisplayPhone"] = "address-book-display-phone";
    CompanyEnabledFeatureKeyType["AddressBookEnableChat"] = "address-book-enable-chat";
    CompanyEnabledFeatureKeyType["PostComments"] = "post-comments";
    CompanyEnabledFeatureKeyType["UsersSelfRegistrationWithoutPredefinedData"] = "users-self-registration-without-predefined-data";
    CompanyEnabledFeatureKeyType["EditUserSelfProfile"] = "edit-user-self-profile";
    CompanyEnabledFeatureKeyType["AllowEmployeesToThank"] = "allow-employees-to-thank";
    CompanyEnabledFeatureKeyType["AllowEmployeesToTransferCurrency"] = "allow-employees-to-transfer-currency";
    CompanyEnabledFeatureKeyType["moodleCoursesIntegration"] = "moodleCoursesIntegration";
    CompanyEnabledFeatureKeyType["moodleUsersIntegration"] = "moodleUsersIntegration";
})(CompanyEnabledFeatureKeyType || (CompanyEnabledFeatureKeyType = {}));
export var RoleType;
(function (RoleType) {
    RoleType["Basic"] = "Basic";
    RoleType["Manager"] = "Manager";
    RoleType["Admin"] = "Admin";
    RoleType["RootAdmin"] = "RootAdmin";
    RoleType["Supervisor"] = "Supervisor";
    RoleType["AdminSupervisor"] = "AdminSupervisor";
})(RoleType || (RoleType = {}));
export var SocialNetwork;
(function (SocialNetwork) {
    SocialNetwork["Vkontakte"] = "Vkontakte";
    SocialNetwork["Instagram"] = "Instagram";
    SocialNetwork["Facebook"] = "Facebook";
    SocialNetwork["Telegram"] = "Telegram";
    SocialNetwork["LinkedIn"] = "LinkedIn";
    SocialNetwork["X"] = "X";
    SocialNetwork["Slack"] = "Slack";
})(SocialNetwork || (SocialNetwork = {}));
export var FeedbackSectionType;
(function (FeedbackSectionType) {
    FeedbackSectionType["ProductOrders"] = "0";
    FeedbackSectionType["Activities"] = "1";
    FeedbackSectionType["OneToOnes"] = "2";
    FeedbackSectionType["Surveys"] = "3";
    FeedbackSectionType["SupportRequests"] = "4";
})(FeedbackSectionType || (FeedbackSectionType = {}));
export var FeedbackTriggerType;
(function (FeedbackTriggerType) {
    FeedbackTriggerType["ProductOrdered"] = "0";
    FeedbackTriggerType["TealRequestCreated"] = "1";
    FeedbackTriggerType["ActivityCreated"] = "2";
    FeedbackTriggerType["ActivityUpdated"] = "3";
    FeedbackTriggerType["CurrencyAccrues"] = "4";
    FeedbackTriggerType["OneToOneCommentCreated"] = "5";
    FeedbackTriggerType["OneToOneCreated"] = "6";
    FeedbackTriggerType["OneToOneUpdated"] = "7";
    FeedbackTriggerType["SurveyCompleted"] = "8";
    FeedbackTriggerType["SurveyCreated"] = "9";
    FeedbackTriggerType["SurveyUpdated"] = "10";
    FeedbackTriggerType["SupportRequestCreated"] = "11";
    FeedbackTriggerType["SupportRequestResolved"] = "12";
})(FeedbackTriggerType || (FeedbackTriggerType = {}));
export var DeviceType;
(function (DeviceType) {
    DeviceType["Mobile"] = "Mobile";
    DeviceType["Web"] = "Web";
})(DeviceType || (DeviceType = {}));
