import { EventEmitter } from 'events';
export var EventsBus;
(function (EventsBus) {
    EventsBus["logout"] = "logout";
    EventsBus["showMessage"] = "showMessage";
    EventsBus["fetchUserRequests"] = "fetchUserRequests";
    EventsBus["editPostPinned"] = "editPostPinned";
    EventsBus["editPostState"] = "editPostState";
    EventsBus["editPostCommetsCount"] = "editPostCommetsCount";
    EventsBus["createPost"] = "createPost";
    EventsBus["editProfile"] = "editProfile";
    EventsBus["editActivitySuggestionStatus"] = "editActivitySuggestionStatus";
    EventsBus["createActivity"] = "createActivity";
    EventsBus["editActivity"] = "editActivity";
    EventsBus["updateCustomizations"] = "updateCustomizations";
    EventsBus["editAward"] = "editAward";
    EventsBus["openCreateRequestForm"] = "openCreateRequestForm";
    EventsBus["addUserApplication"] = "addUserApplication";
})(EventsBus || (EventsBus = {}));
export var EE = new EventEmitter();
