var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import { Typography } from 'ui-kit/typography';
import { Tooltip } from 'ui-kit/tooltip';
import { Icon } from 'ui-kit/icon';
import { CheckBoxOffSquareIcon, CheckBoxOnSquareIcon } from './icons';
import { useFieldState } from 'ui-kit/controllers/selects/hooks/use-field-state';
import { OPTION_POSTFIX_TESTID } from 'ui-kit/controllers/selects/constants';
import styles from './options-list.module.scss';
var OptionListItem = memo(function (_a) {
    var _b;
    var value = _a.value, label = _a.label, desc = _a.desc, active = _a.active, disabled = _a.disabled, multiple = _a.multiple, dataTestid = _a.dataTestid, tooltipText = _a.tooltipText, onClick = _a.onClick;
    var content = (_jsxs("div", __assign({ className: cs(styles.option, (_b = {}, _b[styles.active] = active, _b[styles.disabled] = disabled, _b)), title: "".concat(label).concat(desc ? "\n".concat(desc) : ''), "data-testid": dataTestid, onClick: disabled ? undefined : function () { return onClick(value); } }, { children: [multiple && (_jsx(Icon, { className: styles.icon, color: active ? 'contrast' : 'primary', component: active ? CheckBoxOnSquareIcon : CheckBoxOffSquareIcon })), _jsxs(Typography, __assign({ className: styles.optionLabel, variant: "body-s" }, { children: [label, desc ? (_jsx(Typography, __assign({ className: styles.optionDesc, variant: "body-xs", component: "span" }, { children: desc }))) : null] }))] })));
    return tooltipText ? (_jsx(Tooltip, __assign({ content: tooltipText, placement: "bottom" }, { children: content }))) : (content);
});
export var OptionsList = function (_a) {
    var name = _a.name, value = _a.value, _b = _a.options, options = _b === void 0 ? [] : _b, multiple = _a.multiple, setSubscriber = _a.setSubscriber, onSelect = _a.onSelect;
    var t = useTranslation().t;
    var userQuery = useFieldState({ key: 'searchString', initialValue: '', setSubscriber: setSubscriber });
    var preparedUserQuery = userQuery.trim();
    var filteredOptions = options;
    if (preparedUserQuery) {
        var loweredPreparedUserQuery_1 = preparedUserQuery.toLowerCase();
        filteredOptions = options.filter(function (_a) {
            var label = _a.label, desc = _a.desc;
            return label.toLowerCase().includes(loweredPreparedUserQuery_1) ||
                (desc && desc.toLowerCase().includes(loweredPreparedUserQuery_1));
        });
    }
    return (_jsx(_Fragment, { children: filteredOptions.length ? (filteredOptions.map(function (item, index) { return (_jsx(OptionListItem, __assign({}, item, { multiple: multiple, active: value.includes(item.value), dataTestid: "".concat(name, "-").concat(OPTION_POSTFIX_TESTID, "-").concat(index), onClick: onSelect }), item.value)); })) : (_jsx(Typography, __assign({ className: styles.emptyOptionsList, variant: "body-s" }, { children: t('uiKit.optionsList.emptyText') }))) }));
};
